import React from 'react'
import Logo from '../../ui/Logo/Logo'
import './Top.sass'
import SiteNavLink from '../Nav/SiteNavLink'
import Icon from '../../ui/Icon/Icon'
import { Link } from 'react-router-dom'

function Top() {
  return (
    <section className="Top">
      <div className="container">
        <div className="row">
          <div className="col-12">
            <div className="Top-Container">
              <Logo navLink path={'/'} />
              <div className="Top-Right">
                <div className="Top-Nav">
                  <SiteNavLink title={'Главная'} path="/" />
                  <SiteNavLink title={'Оплата и доставка'} path="/delivery" />
                  <a
                    className="Site-NavLink"
                    href="https://diantusopt.ru/"
                    target="blank"
                  >
                    Партнёрам
                  </a>
                  <SiteNavLink title={'Контакты'} path="#contacts" />
                </div>
                <div className="TopContact">
                  <a href="tel: +7 (8152) 60-19-40" className="ContactIcon">
                    <Icon name="mobile" />
                  </a>
                  <div className="ContactPhone">
                    <a className="Phone-Number" href="tel: +7 (8152) 60-19-40">
                      +7 (8152) 60-19-40
                    </a>
                    <p className="Phone-Desc">Пн-Вс: с 09:00 до 20:00</p>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
  )
}

export default Top
