import React, { useEffect, useState } from 'react'
import './Categories.sass'
import { find, isEmpty, orderBy, toNumber } from 'lodash'
import getCollection from '../../utils/db/getCollection'
import { NavLink } from 'react-router-dom'
import Icon from '../../ui/Icon/Icon'

function Categories() {
  const [links, setLinks] = useState(null)

  useEffect(() => {
    getCollection({
      path: 'categories',
      docIdName: 'categoryId',
      // orderBy: { fieldPath: 'position', orderDir: 'descs' },
    }).then((data) => {
      const order = orderBy(
        data
          .filter((d) => !d.isDeleted && true)
          .map((d) => {
            d.position = toNumber(d.position)
            return d
          }),
        ['position'],
        ['asd']
      )
      setLinks(order)
    })
  }, [])

  return (
    <section className="Categories">
      <div className="container">
        <div className="row">
          <div className="col-12">
            <div className="Categories-Container">
              {!isEmpty(links) &&
                links.map((l) => (
                  <>
                    <NavLink
                      to={`/products/${l.categoryId}`}
                      className="CategoryTile"
                      style={{
                        backgroundImage: `url(${
                          !isEmpty(l.photos)
                            ? find(l.photos, ['isAvatar', true]).publicUrl
                            : null
                        })`,
                      }}
                    >
                      <p>{l.title}</p>
                      <div className="PriceLine">
                        <div className="PriceFrom">
                          от <span className="Price">300</span> ₽
                        </div>
                        <div className="Icon-Container">
                          <Icon name="angle-right" weight={'solid'} />
                        </div>
                      </div>
                    </NavLink>
                  </>
                ))}
            </div>
          </div>
        </div>
      </div>
    </section>
  )
}

export default Categories
